import React from "react";
import Layout from "../components/common/Layout/layout";
import SEO from "../components/common/Seo/seo.js";
import Anchor from "../components/common/Anchor";
import Box from "../components/common/Box";
import Button from "../components/common/Button";
import ResponsiveText from "../components/common/ResponsiveText";
import ResponsiveTitle from "../components/common/ResponsiveTitle";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box as="main" textAlign="center">
      <ResponsiveText min={48} max={86}>
        <span role="img" title="Shocked face" aria-label="Shocked face">
          😲
        </span>
      </ResponsiveText>
      <ResponsiveTitle min={32} max={48}>
        This link doesn&#39;t exist....
      </ResponsiveTitle>
      <Box margin="md">
        <Anchor to="/">
          <Button>Take Me Home</Button>
        </Anchor>
      </Box>
    </Box>
  </Layout>
);

export default NotFoundPage;
